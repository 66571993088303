@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StaticPopup {
    position: fixed;
    top: 100%;
    left: 50%;
    z-index: 1000;
    border-radius: 21px;
    box-shadow: 0 16px 16px 4px rgba(0, 0, 0, 0.1);
    width: 96%;
    max-width: 1398px;
    background: $color-neutral-5;
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 0);
    overflow: hidden;

    @include default-transition();

    &_isVisible {
        visibility: visible;
        opacity: 1;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &-Close {
        background: rgba(245, 245, 245, 1);
        width: 55px;
        height: 55px;
        border-radius: 50%;
        position: absolute;
        top: 28px;
        right: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

@include mobileAndTablet {
    .StaticPopup {
        max-height: 90%;
        overflow-y: scroll;

        &-Close {
            transform: scale(0.7);
            top: 0;
            right: 0;
        }
    }
}
