@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StaticSlider {
    padding-left: 75px;
    padding-right: 75px;

    &-Item {
        padding: 0 30px 0;
    }

    .SliderNextArrow {
        right: -83px;
        z-index: 1;
    }

    .SliderPrevArrow {
        left: -83px;
        z-index: 1;
    }

    .slick-dots {
        bottom: -44px;

        li {
            background: $color-primary-95;
            width: 15px;
            height: 3px;
            opacity: 0.5;

            &.slick-active {
                opacity: 1;
            }
        }
    }
}

@include mobile {
    .StaticSlider {
        padding: 0;
        margin: 0 -16px;

        .StaticSlider {
            &-Item {
                padding: 0;
            }
        }

        .SliderNextArrow {
            right: 4px;
            bottom: 100px;
        }

        .SliderPrevArrow {
            left: 4px;
            bottom: 100px;
        }
    }
}

@include tablet {
    .StaticSlider {
        padding: 0 50px;

        .SliderNextArrow {
            right: -50px;
            bottom: 50%;
        }

        .SliderPrevArrow {
            left: -50px;
            bottom: 50%;
        }
    }
}

