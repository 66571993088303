@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


.ChevronIcon {
    fill: $color-neutral-60;
    cursor: pointer;

    &_isActive {
        fill: var(--color-black);
    }
}
