@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchField {
    &-Input {
        width: 100%;
        border: 1px solid $color-teriary-2;
        border-radius: 0;

        &::placeholder {
            color: $color-neutral-50;
        }

        &:focus {
            border: 1px solid $color-neutral-70;
        }
    }

    &-SearchIcon {
        position: absolute;
        top: 0;
        right: 0;
        background: $color-primary-final;
        height: 100%;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid $color-teriary-2;
        border-left: 0;

        @include default-transition();

        &:hover, &_isActive {
            background: $color-primary-50;
            border: 1px solid $color-neutral-70;
            border-left: 0;

            path {
                fill: $color-neutral-5;
            }
        }

        &:focus {
            border: 1px solid $color-neutral-70;
            border-left: 0;
        }

        &_isLoading {
            opacity: 0.3;
        }
    }
}

.MobilePopup {
    .SearchField {
        padding: 24px 16px 0;

        &-SearchIcon {
            background: $color-primary-95;
            border-color: $color-primary-95;

            path {
                fill: $color-neutral-5;
            }
        }
    }
}

@include desktopAndAbove {
    .SearchField {
        &_isActive {
            &::before {
                content: '';
                position: fixed;
                width: 100vw;
                height: 100vh;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                background-color: $color-neutral-90;
                z-index: 1;
                opacity: .2;
            }

            .SearchField-Wrapper {
                z-index: 3;
            }
        }
    }
}
