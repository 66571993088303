@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogSearchOverlay {
    &-Link {
        display: block;
    }
}

.Overlay.BlogSearchOverlay_isVisible {
    margin: 0;

    p {
        margin: 0;
    }
}
