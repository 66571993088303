@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FieldSelect {
    &-Select {
        --input-border-radius: 0;
        --input-border-color: #{$color-neutral-90};

        line-height: 20px;
        padding-top: 14px;
        padding-bottom: 12px;
        padding-left: 32px;
        height: 56px;
        font-size: 15px;

        &_isExpanded {
            --input-border-color-focus: #{$color-neutral-90};
        }

        &_isDisabled {
            opacity: 1;
            --input-border-color: #{$color-neutral-50};

            color: $color-neutral-50;
        }
    }

    &-Arrow {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform .25s ease-out;
        pointer-events: none;

        svg {
            display: block;
        }

        &_isExpanded {
            transform: translateY(-50%) rotate(180deg);
        }

        &_isDisabled {
            svg {
                path {
                    fill: $color-neutral-50;
                }
            }
        }
    }

    &-Options {
        z-index: 11;

        &_isExpanded {
            --input-border-color: #{$color-neutral-90}
        }
    }

    &-Option {
        line-height: auto;
        margin-bottom: 0;
        padding-left: 16px;
        color: $color-neutral-50;
        transition: opacity .25s ease-out;
        font-size: 12px;
        padding-inline-start: 16px;
        padding-bottom: 0;
        --secondary-base-color: none;

        strong {
            font-weight: 600;
        }

        &:first-of-type {
            margin-top: 10px;
        }

        &:last-of-type {
            margin-bottom: 10px;
        }

        &_isSelected, &:hover {
            color: $color-neutral-90;
        }

        &_isPlaceholder {
            opacity: 1;
        }
    }
}

.Field {
    &_variant_secondary {
        .FieldSelect {
            &-Select {
                --input-border-color: #{$color-neutral-20};
                --input-background-color: #{$color-neutral-20};

                &_isExpanded {
                    --input-border-color-focus: #{$color-neutral-20};
                }

                &_isDisabled {
                    --input-border-color: #{$color-neutral-50};
                    --input-background-color: #{$color-neutral-5};
                }
            }

            &-Options {
                background: $color-neutral-20;

                &_isExpanded {
                    --input-border-color: #{$color-neutral-20};
                }
            }

            &-Option {
                background: $color-neutral-20;

                &_isSelected, &:hover {
                    color: $color-primary-50;
                }
            }
        }
    }

    &_variant_teriary {
        .FieldSelect {
            &-Select {
                --input-border-color: #{$color-neutral-35};

                font-size: 13px;
                padding: 7px 16px 6px;
                height: 34px;

                &_isExpanded {
                    --input-border-color-focus: #{$color-neutral-35};
                }

                &_isDisabled {
                    --input-border-color: #{$color-neutral-50};
                    --input-background-color: #{$color-neutral-5};
                }
            }

            &-Arrow {
                right: 9px;

                svg {
                    width: 24px;
                }
            }

            &-Option {
                padding-left: 16px;
                font-size: 13px;
                margin: 14px 0;
                color: $color-neutral-90;
            }

            &-Options {
                &_isExpanded {
                    --input-border-color: #{$color-neutral-30};
                }
            }
        }
    }

    &_size_small {
        .FieldSelect {
            &-Select {
                height: 50px;
            }
        }
    }
}
