@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DiscountLabel {
    color: $white;
    background: $color-other-8;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
    min-width: 53px;
    text-align: center;
    margin-right: 14px;
}
