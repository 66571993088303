.LPBase {
    &_darkBg {
        background-color: $lp-color-1;
    }

    &_blackBg {
        background-color: $lp-color-6;
    }

    &_lightGrayBg {
        background-color: $lp-color-3;
    }

    &_yellowBg {
        background-color: $lp-color-5;
    }

    &_whiteColor {
        color: $lp-color-2;
    }

    &_yellowColor {
        color: $lp-color-5;
    }

    &_greyColor {
        color: $lp-color-4;
    }

    &-Title {
        font-family: $font-dm-serif-display;
        margin-bottom: 14px;
        font-weight: 400;
        font-size: 25px;
        line-height: 34px;

        &_large {
            font-size: 56px;
            line-height: 76px;
            margin-bottom: 30px;
        }

        &_medium {
            font-size: 37px;
            line-height: 50px;
            margin-bottom: 30px;
        }
    }

    &-Paragraph {
        font-size: 15px;
        line-height: 27px;
        margin-bottom: 24px;
        font-weight: 500;

        &:last-child {
            margin-bottom: 0;
        }

        &_secondary {
            font-weight: 400;
        }
    }

    &-Container {
        @include page-container;

        &_secondary {
            padding-top: 75px;
            padding-bottom: 62px;
        }
    }
}

[data-trigger-popup]:not(button) {
    cursor: pointer;

    @include default-transition(all, .1s);

    &:hover {
        opacity: 0.8;
    }
}

@include mobile {
    .LPBase {
        &-Paragraph {
            font-size: 16px;
            line-height: 30px;
        }

        &-Container {
            &_secondary {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }

        &-Title {
            margin-top: 0;

            &_large {
                font-size: 38px;
                line-height: 50px;
                margin-bottom: 20px;
            }

            &_medium {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 20px;
            }
        }
    }
}
