@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PriceToFreeDelivery {
    font-weight: 400;
    font-size: 16px;
    color: $color-neutral-75;
    width: 100%;

    b {
        font-weight: 500;
        color: $color-neutral-90;
    }
}
