@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoriesTag {
    padding: 20px 0;
    border-top: 1px solid $color-neutral-35;

    button {
        margin-top: 20px;
        height: 40px;
        width: 100%;
    }

    &-Items {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 12px;
        overflow: hidden;

        &_isExpanded {
            height: auto;
        }
    }

    &-Heading {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
    }

    &-Header {
        display: flex;
        gap: 16px;
        margin-bottom: 24px;
    }

    &-Category {
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 6px 16px;
        border: 1px solid rgba(0, 0, 0, 0.32);
        color: $color-neutral-70;
        font-weight: 500;
        font-size: 12px;

        @include default-transition();

        &:hover {
            color: $color-neutral-5;
            background: $color-teriary-1;
            border-color: $color-teriary-1;
        }
    }

    &-Switcher {
        height: 90px;
    }
}
