@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 600px;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;
        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }
    }

    &-Image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Crumbs {
        height: 3px;
        position: absolute;
        margin: auto;
        display: grid;
        grid-gap: 20px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 32px);
        justify-content: center;
        inset-block-end: -26px;
    }

    &-Crumb {
        width: 32px;
        height: 3px;
        background: $color-neutral-40;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;

        &_isActive {
            background: $color-primary-50;
        }
    }

    &-Arrow {
        cursor: pointer;
        position: absolute;
        inset-block-start: 50%;
        width: 50px;
        height: 50px;

        .ChevronIcon {
            width: 100%;
            height: 100%;
        }

        &_isPrev {
            inset-inline-start: 0;

            &:dir(ltr) {
                transform: rotate(180deg) translateY(50%) scale(-1);
            }

            &:dir(rtl) {
                transform: translateY(50%);
            }
        }

        &_isNext {
            inset-inline-end: 0;

            &:dir(ltr) {
                transform: translateY(-50%);
            }

            &:dir(rtl) {
                transform: rotate(180deg) translateY(-50%) scale(-1);
            }
        }

        &_isDisabled {
            opacity: .5;
        }
    }
}
