.InfoTiles {
    margin-bottom: 100px;
    position: relative;
    background: $color-neutral-10;

    &-Item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 24px;

        &:last-child {
            margin-right: 0;
        }
    }

    &-Title {
        margin-top: 0;
    }

    &-Icon {
        margin-bottom: 24px;
        height: 30px;
    }

    &-Text {
        color: $color-neutral-90;
    }
}

.CmsBlock-Wrapper {
    &_type_category {
        background-color: $color-neutral-10;

        .InfoTiles {
            @include page-container;
        }
    }
}

@include mobile {
    .InfoTiles {
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 45px;

        &-Items {
            display: flex;
            flex-wrap: wrap;
        }

        &-Item {
            padding: 24px;
            width: 50%;
            justify-content: start;
            margin-bottom: 0;

            &::after {
                width: calc(100% - 48px);
                height: 1px;
                position: absolute;
                content: '';
                bottom: 0;
                left: 24px;
                background: $color-teriary-8;
            }

            &:last-child, &:nth-last-child(2) {
                &::after {
                    display: none;
                }
            }
        }

        &-Title {
            font-size: 13px;
            line-height: 20px;
            text-align: center;
        }

        &-Icon {
            margin-bottom: 16px;
        }
    }
}

@include tabletAndAbove {
    .InfoTiles {
        position: relative;

        &-Items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-Item {
            padding: 0 32px;
            margin: 75px 0 50px;
            width: 48.2%;
        }
    }
}

@include desktopAndAbove {
    .InfoTiles {
        &-Item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            flex: 1;
            width: auto;

            &:not(:last-child)::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 85%;
                width: 1px;
                background-color: $color-teriary-5;
            }
        }

        &-Title {
            margin-bottom: 12px;
        }
    }
}
