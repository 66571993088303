@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.Blog-Sidebar {
    .BlogSearchBar {
        &-SearchWrapper {
            margin-left: 0;
            margin-right: 0;
        }

        &-Search {
            margin-top: 0;
            height: fit-content;

            @include mobile {
                &_isActive {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                }
            }
        }

        &-SearchInput {
            border: 1px solid $color-neutral-90;
            width: 100%;
            border-radius: 0;
        }

        &-Button {
            position: absolute;
            top: 6px;
            right: 1px;
            background: $color-neutral-20;
            height: 50px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: background 0.3s ease-in-out;

            svg {
                display: block;
            }
        }
    }
}

