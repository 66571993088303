@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.BlogRelatedPosts {
    &-TitleWrapper {
        height: fit-content;
        margin-bottom: 21px;
    }

    &-Title {
        width: fit-content;
        font-size: 2.5rem;
        margin-bottom: 0;
    }

    &-Wrapper {
        margin-top: 50px;
    }

    &-PostsWrapper {
        display: grid;
        grid-gap: 40px 20px;
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-template-columns: 1fr;

        .BlogPostCart {
            margin: 0;

            &-Title {
                font-size: 14px;
                font-weight: 400;
                margin: 2rem 0 1rem;
            }
        }

        @include desktop {
            grid-gap: 20px 20px;
        }
    }
}
