.SeoContent {
    margin-bottom: 65px;

    &, & p {
        color: $color-neutral-70;
        line-height: 21px;
        font-weight: 400;
        font-size: 14px;
    }
}

@include tabletAndAbove {
    .SeoContent {
        margin-bottom: 86px;

        h1 {
            font-size: var(--h1-font-size);
        }
    }
}
