.LoyaltyProgramPage {
    background: var(--footer-background);
    background: $loyalty-content-gradient;
    padding-block-end: 56px;
    text-align: center;

    $parent: &;

    @include desktopAndAbove {
        padding-block-end: 90px;
    }

    &-Container {
        @include page-container;
    }

    &-Header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 125px 65px;
        margin-block-end: 36px;
        background-color: $color-primary-95;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../../assets/images/loyalty-program/header.png");

        @include desktopAndAbove {
            padding-block: 245px 135px;
            margin-block-end: 56px;
        }

        & > #{$parent}-Container {
            margin: auto;
        }

        #{$parent}-Heading,
        #{$parent}-Paragraph {
            color: $white;
        }

        #{$parent}-Paragraph {
            &:last-of-type {
                margin-block-end: 56px;
            }
        }
    }

    &-Logo {
        margin: auto;
        max-width: 60%;
        margin-block-end: 48px;

        @include desktopAndAbove {
            margin-block-end: 96px;
            max-width: 630px;
        }

        @include tabletAndAbove {
            max-width: 50%;
        }

        & + #{$parent}-Heading {
            margin-bottom: 48px;

            @include desktopAndAbove {
                margin-block-end: 96px;
            }
        }
    }

    &-Heading {
        font-family: $font-poppins;
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        color: $loyalty-color-primary;
        margin-block: 0 24px;

        @include desktopAndAbove {
            font-size: 36px;
            line-height: 48px;
        }

        &_medium {
            font-size: 30px;
            line-height: 42px;
            font-weight: 400;

            @include desktopAndAbove {
                font-size: 48px;
                line-height: 64px;
            }
        }

        &_large {
            font-family: $font-dm-serif-display;
            font-style: italic;
            font-size: 42px;
            line-height: 54px;
            font-weight: 700;

            @include desktopAndAbove {
                font-size: 72px;
                line-height: 96px;
            }
        }

        &_center {
            text-align: center;
        }
    }

    &-Paragraph {
        font-family: $font-poppins;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;

        @include desktopAndAbove {
            font-size: 24px;
            line-height: 33px;
        }
    }

    &-Link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        padding-inline: 36px;
        font-family: $font-poppins;
        font-size: 16px;
        line-height: 24px;
        background: $loyalty-color-primary;
        color: $white;

        @include desktopAndAbove {
            height: 64px;
            font-size: 24px;
            line-height: 33px;
        }

        &:hover {
            background: lighten($loyalty-color-primary, 8);
            color: $white;
        }
    }

    &-Accordion {
        &.Accordion-Box {
            padding-inline: 0;
            padding-block: 0;

            &:first-of-type {
                border-block-start: 0;
            }

            border-block-end: 0;
        }

        &Title {
            &.Accordion-Header {
                font-family: $font-poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: $loyalty-color-primary;

                &::after {
                    content: url('../../../assets/icons/plus.svg');
                    position: relative;
                }

                @include desktopAndAbove {
                    font-size: 24px;
                    line-height: 33px;
                }

                &_isOpen {
                    &::after {
                        content: url('../../../assets/icons/minus.svg');
                    }
                }
            }
        }

        &Text {
            text-align: center;
        }
    }

    &-StepList {
        display: grid;
        grid-column-gap: 48px;
        align-items: flex-start;
        margin-block-end: 42px;

        @include desktopAndAbove {
            grid-template-columns: repeat(3, 1fr);
            margin-block-end: 64px;
        }
    }

    &-Step {
        background: $white;
        box-shadow: $loyalty-step-gradient;
        padding-inline: 32px;
        padding-block: 64px 50px;
        margin-block-start: 90px;
        text-align: center;

        @include tabletAndAbove {
            padding-block: 138px 100px;
        }

        &Image {
            position: absolute;
            inset-block-start: 0;
            inset-inline: 0;
            transform: translateY(-50%);

            svg {
                height: 90px;
                width: 100%;

                @include tabletAndAbove {
                    height: 175px;
                }
            }
        }
    }
}
