@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.SocialLoginButtons {
    width: 100%;

    &-Row {
        display: flex;
        flex-direction: column;
    }

    &-Column {
        flex: 50%;
    }
}
