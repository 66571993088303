.Banner {
    &_type_rows {
        margin-bottom: 24px;

        .Banner-Item, .mst-banner__placeholder {
            margin-bottom: 24px;
            overflow: hidden;
            cursor: pointer;

            &:hover {
                img {
                    transform: scale(1.02);
                }
            }

            a {
                display: block;
                height: 100%;
            }

            img {
                display: block;

                @include default-transition(transform);
            }
        }

        .Banner {
            &-Title {
                text-align: center;
                margin-bottom: 48px;
            }

            &-Bar {
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                text-align: center;
                background: $white-transparent;
                padding: 30px 30px 24px;
            }

            &-Header {
                margin-bottom: 20px;
                margin-top: 0;
                font-family: $font-dm-serif-display;
            }
        }
    }
}

@include mobile {
    .Banner {
        &_type_rows {
            .Banner {
                &-Header {
                    font-size: 24px;
                    line-height: 34px;
                }

                &-Title {
                    margin-bottom: 24px;
                }
            }
        }
    }
}

@include tabletAndAbove {
    .Banner {
        &_type_rows {
            .Banner-Item, .mst-banner__placeholder {
                height: 220px;

                &:nth-child(odd) {
                    .Banner-Bar {
                        right: 0;
                        left: auto;
                    }
                }

                img {
                    object-fit: cover;
                }

                &.Banner-Item_variant {
                    &_secondary {
                        height: auto;

                        img {
                            object-fit: initial;
                        }
                    }
                }
            }

            .Banner {
                &-Bar {
                    width: 320px;
                    text-align: left;
                    padding: 30px 24px 30px;
                    min-height: 100%;
                }
            }
        }
    }
}

@include desktopAndAbove {
    .Banner {
        &_type_rows {
            .Banner-Item, .mst-banner__placeholder {
                &:nth-child(odd) {
                    .Banner-Bar {
                        right: 128px;
                    }
                }

                &:nth-child(even) {
                    .Banner-Bar {
                        left: 128px;
                    }
                }
            }

            .Banner {
                &-Bar {
                    width: 400px;
                    padding: 30px 60px 30px;
                }
            }
        }
    }
}
