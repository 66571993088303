.LPPopup {
    &-Content {
        display: flex;
        align-items: center;
    }

    &-Text {
        padding: 0 35px;
        flex: 1;
    }

    iframe {
        flex: 0 0 auto;
        max-width: 50%;
    }

    picture {
        width: 50%;
    }

    img {
        width: 100% !important;
        height: auto !important;
        display: block;
    }
}

@include mobile {
    .LPPopup {
        iframe {
            max-width: 100%;
        }

        &-Text {
            padding: 25px;
            order: 1;
        }

        picture {
            order: 0;
            width: 100%;
        }

        &-Content {
            flex-direction: column;
        }
    }
}

@include desktopAndAbove {
    .LPPopup {
        &-Text {
            padding: 0 80px;
        }

        picture {
            width: 42%;
        }
    }
}
