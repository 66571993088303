@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AdvoxPopup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 400;
    background: rgba(0 ,0 ,0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-Close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background: $color-neutral-5;
        cursor: pointer;
        overflow: hidden;

        svg {
            display: block;
            transform: rotate(0);

            @include default-transition();

            &:hover {
                transform: rotate(-90deg);
            }
        }
    }

    &-Content {
        max-width: 600px;
        max-height: 90%;
        background: $color-neutral-5;
        overflow-y: auto;
        cursor: initial;
    }

    &-Banner {
        width: 100%;
        display: block;
    }

    &-ContentWrapper {
        padding: 32px;
    }

    &-Description {
        margin-bottom: 20px;

        p {
            color: rgba(0, 0, 0, 0.48);
            margin-bottom: 0;
            font-size: 13px;
        }
    }

    &-PromoButton {
        .Button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            width: 100%;

            &:hover {
                background: $color-primary-85 !important;
                border-color: $color-primary-85 !important;
            }
        }
    }

    .NewsletterSubscription {
        .Button {
            top: 0;
            width: 190px;
            background: $color-other-3;
            border-color: $color-other-3;

            &:hover {
                background: $color-primary-85;
                border-color: $color-primary-85;
            }
        }
    }

    .Field {
        &_type_checkbox {
            margin-bottom: 0;
        }
    }
}

@include mobile {
    .AdvoxPopup {
        &-Content {
            max-width: 94%;
        }

        &-ContentWrapper {
            padding: 24px;
        }

        .NewsletterSubscription {
            .Button {
                width: 120px;
            }
        }
    }
}
