@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-categories-border: 1px solid lightgray;
    --blog-category-chewron-border: 1px solid gray;
    --blog-category-link-font-size: 14px;
    --blog-category-chewron-size: 10px;
}

.BlogCategories {
    &-Category {
        display: block;
        border-top: none;
        text-align: left;
        margin: 16px 0;

        &:last-child {
            border-bottom: none;
            margin-bottom: 20px;
        }

        &::after {
          display: none;
        }

        .BlogSubCategory {
            margin-top: 1.6rem;
            margin-left: 2rem;
        }

        .BlogCategoryTitle {
            color: var(--link-color);

            &:hover {
                text-decoration: underline;
                color: var(--link-hover);
            }

            span {
                color: var(--color-black);
            }
        }
    }

    &-Title {
        text-align: center;
        text-transform: uppercase;
        margin-top: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.8rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        margin-bottom: 0;
        border-bottom: var(--blog-categories-border);

        + div {
            margin-top: 20px;
        }
    }

    &-SubCategoryTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin: 0;
    }
}
