@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --content-wrapper-width: #{$page-max-width-cms};
}

.CmsPage {
    margin-top: 0;

    &-Wrapper {
        .InspirationPage {
            .CatalogWidget .slick-list {
                overflow-x: clip;
                overflow-y: visible;
            }
        }

        margin-top: 0;

        &:not(&_page_width_full) {
            padding-inline: 20px;
        }

        &_page_width_full {
            margin-block-end: 0;
        }
    }

    .widget {
        overflow-x: visible;
    }

    ul,
    ol {
        li {
            &::before {
                position: absolute;
            }
        }
    }

    ul {
        li {
            &::before {
                top: 14px;
            }
        }
    }

    ol {
        li {
            &::before {
                top: 6px;
            }
        }
    }
}

@include mobile {
    .CmsPage {
        &-Wrapper {
            &:not(&_page_width_full) {
                padding-inline: 16px;
            }
        }
    }
}

@include tabletAndAbove {
    .CmsPage {
        &-Wrapper {
            margin-top: 0;
        }
    }
}

@include desktopAndAbove {
    .CmsPage {
        &-Wrapper {
            &:not(&_page_width_full) {
                padding-inline: 44px;
                overflow-x: hidden; // Needed to achieve product sliders on HP with overflow content
            }
        }
    }
}
