@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 18px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    @include mobileAndTablet {
        border-block-start: 1px solid var(--expandable-content-divider-color);

        &:last-of-type {
            border-block-end: 1px solid var(--expandable-content-divider-color);
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &_iconVariant_secondary {
            justify-content: initial;

            svg {
                margin-left: 5px;
            }
        }

        @include mobileAndTablet {
            padding-block: 14px;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4;
        word-break: break-all;
        display: flex;
        align-items: center;
        gap: 17px;

        @include mobileAndTablet {
            font-size: 14px;
        }

        @include desktopAndAbove {
            font-size: 14px;
            font-weight: bold;
            text-transform: none;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;

        @include mobileAndTablet {
            font-size: 12px;
        }
    }

    @include mobileAndTablet {
        border-block-start: 0;

        &:last-of-type {
            border-block-end: 0;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        margin-top: 15px;

        @include mobileAndTablet {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        &_isContentExpanded {
            @include mobileAndTablet {
                @include expanded-content;
            }
        }

        &_isExpandableOnDesktop {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &.ExpandableContent-Content_isContentExpanded {
                @include expanded-content;
            }
        }
    }

    @include mobileAndTablet {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}

@include desktopAndAbove {
    .CategoryFilterOverlay {
        .ExpandableContent {
            &-Content {
                position: absolute;
                top: 100%;
                left: -1px;
                width: 335px;
                border: 2px solid $color-neutral-90;
                background: $color-neutral-5;
                padding: 24px 24px 32px;
                max-height: initial;
                margin-top: -2px;
                visibility: hidden;

                &_isContentExpanded {
                    visibility: visible;
                }
            }

            &-Button {
                border: 1px solid $color-neutral-90;
                outline: 1px solid transparent;
                padding: 6px 20px 6px 24px;

                &_isContentExpanded {
                    outline: 1px solid $color-neutral-90;
                }
            }
        }
    }
}
