@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Label {
    font-size: 12px;
    line-height: 17px;
    padding: 8px 10px 7px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }

    &_iconOnly {
        padding: 0;
        display: block;
    }

    &-Image {
        margin-right: 10px;
        max-width: 20px;

        &_iconOnly {
            max-width: 50px;
            margin-right: 0;
        }
    }
}
