@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CarouselScroll {
    height: fit-content;
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto 0;
    flex-direction: column;
    justify-content: center;

    &-ContentWrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 28px 15px;
        height: var(--carousel-height)
    }

    &-Content {
        transform: translateY(var(--translateY)) ;
        transition: transform 300ms;
        padding-right: 1px;
        display: flex;
        gap: 32px;
        flex-direction: column;
    }
}
