@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderPrevArrow {
    left: 10px;
    cursor: pointer;
    position: absolute;
    bottom: -14px;

    &_disabled {
        svg {
            opacity: 0.4;
        }
    }

    svg {
        @include default-transition(right, .2s);

        right: 0;

        path {
            fill: $color-neutral-50;
        }
    }

    &:hover {
        svg {
            right: 4px;

            path {
                fill: $color-neutral-90;
            }
        }
    }

    &-Wrapper {
        width: 73px;
        height: 73px;
        background: $color-neutral-25;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_variant {
        &_secondary {
            svg {
                right: 2px;

                path {
                    fill: $color-neutral-90;
                }
            }
        }

        &_tertiary {
            .SliderNextArrow {
                &-Wrapper {
                    background: $color-primary-95;
                }
            }

            svg {
                right: 2px;

                path {
                    fill: $color-neutral-5;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $color-neutral-5;
                    }
                }
            }
        }
    }
}

@include mobileAndTablet {
    .SliderPrevArrow {
        &-Wrapper {
            width: 40px;
            height: 40px;

            svg {
                width: 9px;
                height: 16px;
            }
        }

        &_variant {
            &_secondary, &_secondary:hover, &_tertiary, &_tertiary:hover {
                svg {
                    right: 1px;
                }
            }
        }
    }
}

@include desktopAndAbove {
    .SliderPrevArrow {
        position: absolute;
        bottom: 50%;
        z-index: 1;
        transform: translateY(50%);
    }
}

@include above1480 {
    .SliderPrevArrow {
        left: -44px;
    }
}
