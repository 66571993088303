@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Router {
    &-MainItems {
        min-height: calc(100vh - var(--header-total-height) + 10px);
        overflow-x: hidden;
    }

    &-Loader {
        height: 100vh;
    }
}
