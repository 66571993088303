img.CybotExternalLinkArrow {
    width: auto;
}

.CookiebotWidget-open,
.CybotCookiebotDialogContentWrapper {
    li {
        &::before {
            display: none;
        }
    }

    label {
        display: inline-block;
    }
}
