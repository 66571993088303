@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LowestPriceIn30Days {
    & p {
        color: $dark-transparent-64;
        margin-bottom: 18px;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
    }
}
