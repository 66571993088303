@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


:root {
    --search-item-hover-color: #eee;
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding: 12px;
    transition: all .3s;

    &::before {
        content: none;
    }

    a:hover {
        color: $color-primary-50 ;
    }

    &:hover {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
    }

    &:hover &-Check {
        opacity: 1;
        font-weight: 600;
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: .5;
    }

    &-Check {
        color: $color-primary-50;
        opacity: 0;
        display: flex;
        gap: 8px;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 48px calc(100% - 60px);
        grid-column-gap: 12px;
    }
}

@include mobileAndTablet {
    .SearchItem {
        &-Title {
            font-size: 12px;
        }

        &-Wrapper {
            align-items: center;
        }

        &-Check {
            display: none;
        }
    }
}
