@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FeaturedBrands {
    margin-bottom: 60px;

    img {
        width: auto;
        max-height: 40px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    &-SliderTitle {
        text-align: center;
        margin-bottom: 48px;
    }

    .slick-track {
        display: flex;
        align-content: stretch;
    }

    .slick-slide {
        height: auto;

        > div {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@include mobile {
    .FeaturedBrands {
        &-SliderItem {
            padding-right: 45px;
            height: 60px;
            align-items: center;
            justify-content: center;
            display: flex !important; //Needed to override slider JS style
        }

        &-SliderTitle {
            margin-bottom: 24px;
        }

        .slick-slider {
            margin-right: -20px;
        }
    }
}

@include tabletAndAbove {
    .FeaturedBrands {
        img {
            max-height: 54px;
        }
    }
}

@include desktopAndAbove {
    .FeaturedBrands {
        .SliderPrevArrow {
            left: -40px;
        }

        .SliderNextArrow {
            justify-content: flex-end;
            right: -40px;
        }
    }
}
