@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --checkbox-width: var(--imported_checkboxes_width, 16px);
    --checkbox-height: var(--imported_checkboxes_height, 16px);
    --radio-width: var(--imported_radios_width, 16px);
    --radio-height: var(--imported_radios_height, 16px);
}

.Field {
    margin-top: 0;

    & textarea {
        --input-border-color: #{$color-neutral-20};
        --input-background-color: #{$color-neutral-20};
        --input-padding: 16px 21px 16px 32px;
        --input-color: #{$color-neutral-90};
    }

    &-Label {
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
        color: $color-neutral-90;

        @include mobile {
            font-size: 14px;
        }
    }

    &-ErrorMessages {
        font-size: 13px;
        margin-bottom: 0;
        margin-left: 32px;
        margin-top: 8px;
        color: $color-secondary-70;
    }

    input {
        &::placeholder {
            color: $color-neutral-70;
            opacity: 1;
        }

        &:disabled {
            &::placeholder {
                color: $color-neutral-40;
            }
        }

        &[type='tel'],
        &[type='text'],
        &[type='password'],
        &[type='email'],
        &textarea, {
            --input-border-color: #{$color-neutral-20};
            --input-background-color: #{$color-neutral-20};
            --input-padding: 16px 21px 16px 32px;
            --input-color: #{$color-neutral-90};
        }

        &[type='tel']:focus,
        &[type='text']:focus,
        &[type='password']:focus,
        &[type='email']:focus {
            border-color: #{$color-neutral-20};
        }
    }

    &_type {
        &_number {
            border: 1px solid $color-neutral-20;
            padding: 0;
            width: var(--input-type-qty-width);

            button {
                height: var(--input-type-number-height);
            }

            svg {
                --plus-minus-icon-size: 18px;

                &:hover {
                    --primary-base-color: #{$color-neutral-90};
                }
            }
        }

        &_text,
        &_password,
        &_select,
        &_checkbox,
        &_email,
        &_tel {
            margin-bottom: 20px;
        }
    }

    &_variant_primary {
        &.Field_hasError {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea, {
                --input-color: #{$color-secondary-70};
                --input-background-color: #{$color-neutral-20};

                border: 1px solid $color-secondary-5;
            }
        }

        &.Field_isValid {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea, {
                --input-color: #{$color-neutral-90};
                --input-background-color: #{$color-neutral-20};

                border: 1px solid $color-primary-5;
            }
        }
    }

    &_variant_secondary {
        [type='text'],
        [type='password'],
        [type='email'],
        textarea, {
            --input-background-color: transparent;
            --input-padding: 18px 21px 16px;

            border-radius: 0;
            border-width: 0;
            border-bottom-width: 3px;
            --input-border-color: #{$color-neutral-40};

            &:focus {
                --input-border-color-focus: #{$color-primary-50};
                --input-color: #{$color-neutral-90};
            }

            &:disabled {
                --input-border-color: #{$color-neutral-30};
                --input-color: #{$color-neutral-40};
            }
        }

        &.Field_hasError {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea {
                --input-color: #{$color-secondary-50};

                border: 0;
                border-bottom: 3px solid $color-secondary-50;
            }
        }

        &.Field_isValid {
            [type='text'],
            [type='password'],
            [type='email'],
            [type='tel'],
            textarea {
                --input-color: #{$color-primary-50};

                border: 0;
                border-bottom: 3px solid $color-primary-50;
            }
        }
    }

    &_variant_outline {
        [type='text'],
        [type='password'],
        [type='email'],
        textarea {
            --input-background-color: transparent;
            --input-padding: 18px 21px 16px;

            border-radius: 0;
            border-width: 1px;
            --input-border-color: #{$color-neutral-30};

            &:focus {
                --input-border-color-focus: #{$color-primary-50};
                --input-color: #{$color-neutral-90};
            }

            &:disabled {
                --input-border-color: #{$color-neutral-30};
                --input-color: #{$color-neutral-40};
            }
        }
    }

    // Checkbox & Radio

    &-CheckboxLabel,
    &-RadioLabel {
        color: $color-neutral-70;
        transition: color .25s ease-in;
        align-items: start;
        text-align: left;

        &_checked {
            color: $color-neutral-90;
        }

        &_disabled {
            color: $color-neutral-50;
        }

        span {
            display: block;
        }

        .input-control {
            @include mobile {
                min-width: 16px;
                min-height: 16px;
            }
        }
    }

    &-RadioLabel {
        &:hover {
            cursor: pointer;
        }
    }

    &-CheckboxLabelContent,
    &-RadioLabelContent {
        flex: 1;
        line-height: 22px;

        &, & p {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.5px;
        }

        p {
            color: inherit;
            margin-bottom: 0;
        }

        a {
            color: $color-primary-50;

            &:hover {
                color: $color-primary-60;
            }
        }
    }

    [type="checkbox"], [type="radio"] {
        width: 20px;
    }

    [type="checkbox"] {
        +.input-control {
            --box-color: $white;

            border: 1px solid $color-neutral-30;
            width: var(--checkbox-width);
            height: var(--checkbox-height);
            top: 0;
            flex: 0 0 16px;
            margin-inline-end: 11px;

            &_disabled {
                border-color: $color-neutral-30  !important;
                --box-color: #{$color-neutral-30} !important;

                &::after {
                    --checkmark-color: #{$color-neutral-5};
                }
            }
        }

        &:checked {
            +.input-control {
                --box-color: #{$color-primary-50};

                border-color: $color-primary-50;

                &::after {
                    --checkmark-color: #{$color-neutral-5};
                }

                &_disabled {
                    &::after {
                        --checkmark-color: #{$color-neutral-5} !important;
                    }
                }

                +span {
                    color: $color-primary-50;
                }
            }
        }

        @include tabletAndAbove {
            &:hover {
                &:not(:disabled) {
                    + .input-control {
                        --box-color: #{$color-neutral-35};

                        border-color: $color-neutral-35;

                        &::after {
                            --checkmark-color: #{$color-neutral-90};
                        }
                    }
                }
            }

            &:not(:checked) {
                &:hover {
                    + .input-control {
                        --box-color: #{$color-neutral-35};

                        border-color: $color-neutral-35;

                        &::after {
                            --checkmark-color: #{$color-neutral-90};
                        }
                    }
                }
            }
        }
    }

    [type='radio'] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;

        +.input-control {
            background-color: $color-neutral-5;
            border: 1px solid $color-neutral-70;
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            height: var(--radio-height);
            position: relative;
            padding: 0;
            transition-duration: .25s;
            transition-property: background-color, border-color;
            transition-timing-function: ease-in;
            width: var(--radio-width);
            will-change: background-color, border-color;
            margin-inline-end: 0;

            &_disabled {
                border-color: $color-neutral-30  !important;
                background-color: $color-neutral-30;
            }
        }

        &:checked {
            +.input-control {
                border-color: $color-primary-50;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    box-shadow: none;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: $color-primary-50;
                    position: initial;
                }

                &_disabled {
                    border-color: $color-neutral-50;
                    background-color: $color-neutral-5;

                    &::after {
                        background: $color-neutral-50;
                    }
                }
            }
        }

        &:not(:checked) {
            &:hover {
                +.input-control {
                    border-color: $color-neutral-70;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    [name^="option-"]:not([type="checkbox"]) {
        &:checked {
            +.input-control {
                border-color: $color-other-8;

                &::after {
                    width: 10px;
                    height: 10px;
                    background: $color-other-8;
                }
            }
        }

        +.input-control {
            width: 16px;
            height: 16px;
            max-width: 100%;
            border-color: $color-primary-95;
        }
    }

    &-Message {
        --primary-error-color: #{$color-secondary-50};

        margin-bottom: 0;
        padding-left: 21px;
    }

    [type="file"] {
        padding-block: 0;
        padding-inline: 0;
        border-style: dashed;
        cursor: pointer;
        height: 43px;
        width: auto;
        opacity: 0;
        z-index: 1;

        + label {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            border: 1px solid $black;
            padding-inline: 32px;
            padding-block: 0;
            color: $black;

            @include desktopAndAbove {
                width: max-content;
                justify-content: flex-start;
            }

            svg {
                height: 12px;
                width: 12px;
            }

            span {
                display: block;
                color: $black;
                margin-inline-start: 10px;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.75;
                text-transform: initial;
            }
        }
    }

    &File {
        &-Button {
            width: 159px;
        }

        &-Wrapper {
            display: flex;
            align-items: center;

            & > span {
                padding-inline-start: 18px;
                color: $color-neutral-50;
            }
        }

        &-Remove {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 14px;
            width: 14px;

            svg {
                height: 18px;
                width: 18px;
                min-width: 18px;
            }
        }

        &-List {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-block-start: 16px;

            li {
                &::before {
                    display: none;
                }

                display: inline-grid;
                grid-template-columns: auto 1fr auto;
                align-items: center;
                grid-column-gap: 8px;
                padding-inline-start: 0;
                margin-block-end: 16px;
                margin-inline-end: auto;
                font-size: 12px;
                line-height: 2;
                text-decoration: underline;

                & > svg {
                    height: 12px;
                    width: 12px;
                }
            }
        }

        &-AllowedTypes {
            font-size: 12px;
            line-height: 2;
        }
    }

    &Textarea {
        --input-background-color: #{$color-neutral-20};

        display: flex;

        &::before {
            content: "";
            background: var(--input-background-color);
            position: absolute;
            inset: 0;
            z-index: -2;
        }

        textarea {
            background: transparent;

            &:focus {
                border-color: #{$color-neutral-20};
            }


            &:not(:placeholder-shown) {
                & + .FieldTextarea-Placeholder {
                    visibility: hidden;
                    opacity: 0;
                    height: 0;
                    width: 0;
                    display: none;
                }
            }
        }

        &-Placeholder {
            position: absolute;
            inset: 0;
            cursor: text;
            padding: var(--input-padding);
            color: $color-neutral-70;
            z-index: -1;

            * {
                color: $color-neutral-70;
            }

            li::before {
                background: $color-neutral-70;
            }
        }
    }

    [type='checkbox'], [type='radio'] {
        &:disabled {
            cursor: not-allowed;
        }
    }
}
