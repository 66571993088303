@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Footer {
    &-Logo {
        img {
            max-width: 200px;
        }
    }

    &-Column {
        &_isNewsletter {
            background: $color-neutral-5;
            padding: 30px 18px;

            .Footer-ColumnTitle {
                p {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28px;
                    letter-spacing: 0;
                }
            }

            .Footer-ColumnSubtitle {
                p {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    color: $color-neutral-70;
                }
            }

            .Field-CheckboxLabelContent {
                &, & p {
                    font-size: 12px;
                    color: $color-neutral-50;
                }
            }
        }
    }

    .Field-CheckboxLabel {
        font-size: 12px;
    }


    &-ContactAction {
        flex-direction: column;
        display: flex;
        padding: 18px 0;
        text-align: left;
    }

    &-ActionItem {
        padding: 8px 0;
        display: flex;
        align-items: center;

        svg {
            margin-right: 18px;
            max-width: 21px;
            max-height: 21px;
        }
    }

    &-SuperMarka {
        margin: 0 1.5rem;

        img {
            width: auto;
        }
    }

    &-MethodsOptions {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
        border-top: 1px solid $color-teriary-4;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        gap: 3rem;

        img {
            width: auto;
            margin-left: 2rem;
        }
    }

    &-PaymentMethods , &-ShippingMethos {
        display: flex;
        align-items: center;
    }

    &-SuperBrand {
        text-align: center;
        margin: 74px 0 25px;
    }

    .CmsBlock-Wrapper {
        &_type {
            &_social, &_address {
                width: auto;
            }
        }
    }

    &-Container {
        &_isCheckout {
            padding: 38px 0;

            .Footer {
                &-ContentWrapper {
                    display: flex;
                    justify-content: center;
                    gap: 64px;
                }

                &-ActionItems {
                    display: flex;
                    gap: 64px;
                    justify-content: center;
                }

                &-ActionItem {
                    padding: 0;

                    svg {
                        max-width: 24px;
                        max-height: 24px;
                        margin-right: 12px;
                    }

                    a {
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 16px;
                        color: $color-neutral-90;
                    }

                    &_isPhone {
                        a {
                            color: $color-other-8;
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    .Footer {
        &-ActionItem {
            margin: 0 auto;
        }

        &-SuperMarka {
            img {
                height: 40px;
            }
        }

        &-Social {
            justify-content: space-around;
        }

        &-MethodsOptions {
            gap: 3rem;
            flex-direction: column;
        }

        &-ShippingMethos , &-PaymentMethods {
            display: block;
            width: 100%;
            text-align: center;

            span {
                display: block;
                text-align: center;
                margin-bottom: 2rem;
            }

            img {
                height: 20px;
            }
        }

        &-Container {
            &_isCheckout {
                .Footer {
                    &-ActionItems {
                        display: block;
                    }

                    &-ActionItem {
                        &:first-child {
                            margin-bottom: 22px;
                        }

                        svg {
                            max-width: 21px;
                            max-height: 21px;
                            margin-right: 12px;
                        }

                        a {
                            font-size: 14px;
                        }
                    }

                    &-LogoWrapper {
                        width: 48px;
                        overflow: hidden;
                        display: block;

                        img {
                            height: 48px;
                            width: auto;
                            opacity: 0.4;
                        }
                    }
                }

                .ContentWrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}

@include mobileAndTablet {
    .Footer {
        &-Logo {
            img {
                max-width: 116px;
            }
        }

        &-ColumnTitle {
            font-size: 20px;
        }

        &-Contenct {
            margin-top: 36px;
        }

        &-ContactWrapper {
            display: flex;
            flex-direction: column;
        }

        &-SuperBrand {
            order: 1;
            margin-top: 20px;
            margin-bottom: 40px;
        }

        &-SocialWrapper {
            order: 0;
            text-align: center;
        }

        &-SocialHeader {
            font-size: 16px;
            color: $color-neutral-70;
        }

        &-Social {
            max-width: 175px;
            margin: 20px auto;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        &-SuperMarka {
            img {
                width: 64px;
                height: 64px;
            }
        }

        &-MethodsOptions {
            border-top: 0;
            flex-direction: column;
            padding-bottom: 15px;
        }

        &-ShippingMethos, &-PaymentMethods {
            span {
                color: $color-neutral-70;
                font-size: 16px;
            }

            img {
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &-PaymentMethods {
            img {
                margin-bottom: 24px;
            }
        }

        .Accordion {
            &-Header {
                &::after {
                    top: 2px;
                }
            }
        }

        &-CopyrightContent {
            padding: 24px 0;
            display: block;
            text-align: center;
            color: $color-neutral-90;
        }

        &-Copyright {
            margin-bottom: 20px;
        }

        &-ContactItem, &-ContactAction {
            width: 230px;
            justify-content: start;
            margin-left: auto;
            margin-right: auto;
        }

        &-ContactItem {
            padding-bottom: 24px;
        }

        &-ContactAction {
            padding-top: 0;
        }

        &-ActionItem {
            margin: 0;
            padding-bottom: 24px;
            font-size: 14px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

@include tablet {
    .Footer {
        &-Column {
            &_isNewsletter {
                .Footer-ColumnTitle {
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        &-ContactItems {
            justify-content: space-around;
        }

        &-ShippingMethos , &-PaymentMethods {
            display: block;
            width: 100%;
            text-align: center;

            span {
                display: block;
                text-align: center;
                margin-bottom: 2rem;
            }

            img {
                height: 25px;
            }
        }

        &-PaymentMethods {
            display: block;
        }

        &-SocialWrapper {
            text-align: center;
        }

        &-Social {
            margin: 20px auto;
        }
    }
}

@include tabletAndAbove {
    .Footer {
        &-ContactItems {
            justify-content: space-around;
        }
    }
}

@include desktopAndAbove {
    .Footer {
        &-ContactAction {
            margin-left: 40px;
            font-size: 12px;
            line-height: 18px;
        }

        &-ActionItem {
            font-size: 12px;
            line-height: 18px;
        }

        &-Social {
            margin-bottom: 50px;

            svg {
                height: 18px;
                width: 18px;

                &.Footer-Twitter {
                    width: 22px;
                }
            }
        }

        &-ScrollTop {
            position: absolute;
            width: 40px;
            height: 40px;
            right: 0;
            top: -167px;
            z-index: 1;
            box-sizing: content-box;
            border: 10px solid var(--footer-background);

            svg {
                width: 15px;
            }
        }

        &-Column {
            &_isNewsletter {
                order: 2;
                padding: 88px 44px 80px 116px;
                flex: 0 0 auto;
                width: 596px;
                font-size: 28px;
            }
        }

        &-MethodsOptions {
            flex-direction: column;
        }
    }
}

@include above1520 {
    .Footer {
        &-MethodsOptions {
            flex-direction: row;
        }

        &-ScrollTop {
            right: -50px;
        }
    }
}
