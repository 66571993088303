@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.KeyValueTable {
    &-Heading {
        padding: 17px 32px;
        background-color: $color-neutral-15;
    }
}
