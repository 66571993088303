.LPTextColumns {
    &-Content {
        display: flex;
        text-align: center;
        justify-content: space-around;
        padding: 50px 0 100px;
    }

    &-Column {
        padding: 0 40px;
    }

    &-Bar {
        width: 1px;
        position: relative;
        background: none;

        &::before {
            content: '';
            position: absolute;
            bottom: 6px;
            left: 0;
            width: 1px;
            height: 58%;
            background-color: $lp-color-6;
        }

        &.LPBase_lightGrayBg {
            &::before {
                background-color: $lp-color-7;
            }
        }
    }
}

@include mobile {
    .LPTextColumns {
        &-Content {
            flex-direction: column;
            padding: 50px 0 50px;
        }

        &-Bar {
            display: none;
        }

        &-Column {
            width: 100%;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
