@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AnnouncementNotice {
    padding: 16px 25px 16px 20px;
    color: $color-primary-85;
    background: $color-primary-final;
    min-height: 40px;

    &_noClose {
        padding-right: 20px;
    }

    &-Content {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            margin-right: 16px;
            flex-shrink: 0;
        }
    }

    &-Text {
        text-align: left;

        p {
            margin-bottom: 0;
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
        }
    }

    &-CloseIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -15px;
        transform: rotate(0);
        transform-origin: center;

        @include default-transition(transform, .2s);

        &:hover {
            transform: rotate(90deg);
        }

        svg {
            display: block;
        }
    }
}

@include mobileAndTablet {
    .AnnouncementNotice {
        &-Content {
            font-size: 12px;
            justify-content: space-between
        }

        &-CloseIcon {
            position: relative;
            right: 0;
            top: 0;
            margin-top: 0;
        }
    }
}

@include desktopAndAbove {
    .AnnouncementNotice {
        padding: 8px 45px;

        &_noClose {
            padding-right: 20px;
            padding-left: 20px;
        }

        &-Text {
            text-align: center;
        }
    }
}
