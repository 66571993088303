.YourIdeas {
    margin-bottom: 80px;

    a {
        color: $color-primary-70;
    }

    &-Medialink {
        color: $color-primary-70;
    }

    &-Header {
        text-align: center;
    }

    &-Paragraph {
        text-align: center;
        margin-bottom: 48px;
        color: $color-teriary-7;
        padding: 0 70px;

        &.TypographyParagraph_variant_normal {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 22px;
        }
    }

    &-Item {
        width: 23.7%;
        overflow: hidden;

        a {
            display: block;
            color: $white;
        }

        img {
            display: block;
        }

        &.Wide {
            width: 49%;

            .Item-Paragraph, .Item-Header {
                max-width: 90%;
            }
        }

        .Item {
            position: relative;

            &-Content {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 50px 24px;
                z-index: 2;
                background-color: $dark-transparent;
                opacity: 0;

                @include default-transition(all, .3s);

                &:hover {
                    opacity: 1;

                    @include default-transition(all, .3s);
                }
            }

            &-Header {
                font-weight: 500;
                color: $white;
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 16px;
            }

            &-Paragraph {
                color: $white;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 16px;
            }

            &-Button {
                color: $white;
                font-size: 15px;
                font-weight: 500;
                border: 2px solid $white;
                padding: 14px 0;
                max-width: 277px;
                width: 100%;
                text-align: center;

                @include default-transition();

                &:hover {
                    background: $white;
                    color: $color-neutral-90;

                    a {
                        color: $color-neutral-90 ;
                    }
                }
            }
        }
    }

    &-Row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;
    }
}

@include mobile {
    .YourIdeas {
        margin-bottom: 0;

        &-Row {
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        &-Item {
            flex: 0 0 47%;
            margin-bottom: 24px;

            &.Wide {
                flex: 1 1 100%;
            }
        }

        &-Paragraph {
            padding: 0;
        }

        &-Header {
            margin-bottom: 16px;
        }
    }
}

@include mobileAndTablet {
    .YourIdeas {
        &-Item {
            .Item {
                &-Paragraph {
                    display: none;
                }

                &-Header {
                    display: none;
                }

                &-Content {
                    opacity: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $dark-light-transparent;
                }
            }
        }
    }
}
