@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewVersionPopup {
    @include mobile {
        align-items: flex-end;
        height: 100%;
        inset-block-start: 0;
    }

    &-Heading {
        font-size: 20px;
        text-decoration: none;
        font-weight: 400;
        margin-block-start: 0;
        margin-block-end: 12px;

        @include mobile {
            margin-block-end: 14px;
        }
    }

    &-Buttons {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }

        @include desktop {
            display: flex;
            justify-content: space-between;
        }
    }

    &-ReloadButton {
        @include mobile {
            width: 100%;
        }
    }

    &-DismissButton {
        @include mobile {
            width: 100%;
            margin-block-start: 5px;
        }
    }

    &-Content {
        @include mobile {
            padding-block-end: 24px;
        }

        @include desktop {
            max-width: 500px;
        }
    }

    .Popup {
        &-CloseBtn {
            display: none;
        }

        &-Heading {
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }
}
