@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.BannerTiles {
    margin-bottom: 30px;
    min-height: 300px;

    &-Link {
        display: block;
    }

    &-Tile {
        margin-bottom: 10px;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.02);
            }
        }
    }

    img {
        display: block;

        @include default-transition(transform);
    }
}

@include tabletAndAbove {
    .BannerTiles {
        &-Tiles {
            margin-bottom: 60px;
        }
    }
}

@include desktopAndAbove {
    .BannerTiles {
        &-Tiles {
            display: grid;
            grid-template-columns: 61% auto;
            grid-template-rows: auto auto;
            grid-gap: 10px;
        }

        &-Tile {
            margin-bottom: 0;

            &:first-child {
                grid-row-start: span 2;
            }
        }
    }
}
