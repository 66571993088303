@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RewardPoints {
    &-Balance {
        font-size: 14px;
        font-weight: 400;
        color: $dark-transparent;
        margin-bottom: 16px;
        margin-top: 16px;
        display: flex;
        gap: 5px;
    }

    &-BalanceBolded {
        color: $black;
        font-weight: 500;
        display: flex;
        gap: 10px;
    }

    &-Notification {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 40px;
        padding: 10px;
        margin-bottom: 16px;
        font-size: 15px;
        color: $white;
        background-color: $color-other-4;
    }

    &-Checkbox {
        margin-top: 22px;

        .Field.Field_type_checkbox {
            margin-bottom: 0;

            .input-control {
                flex: 0 0 24px;
                height: 24px;
                max-width: 24px;
                margin-right: 16px;
                border: 1px solid $color-neutral-30;
            }
        }
    }

    &-CheckboxLabel {
        font-size: 14px;
        font-weight: 400;
        color: $color-neutral-70;
    }

    &-CheckboxLabelBolded {
        display: inline-block;
        font-weight: 500;
        color: $black;
    }

    &-JoinLoyaltyProgram {
        font-size: 14px;
        color: $black;
        padding-top: 24px;
    }

    &-Buttons {
        display: flex;
        gap: 16px;
        margin-top: 16px;

        & > .RewardPoints-Button {
            padding: 0;
            width: 141px;
        }
    }
}
