@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MobilePopup {
    &-Content {
        .Menu-SubMenu_isVisible .Menu-SubMenu_isVisible .Menu-ItemCaption_type_subcategory {
            padding-left: 32px;
            background-color: $color-neutral-20;
        }


        .Menu-ItemList.Menu-ItemList_type_subcategory {
            .Menu-Link.Menu-Link_isHovered {
                display: none ;

                figcaption {
                    padding-left: 32px;
                }
            }

            .Menu-SubCatLink_inner {
                flex-direction: row;

                .Menu-Link.Menu-Link_isHovered {
                    display: block;
                }
            }
        }
    }
}
