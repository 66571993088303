
.NewsletterPage {
    @include page-container;

    margin: 0 auto;
    max-width: var(--content-wrapper-width);
    overflow-x: hidden;

    &-ContentTop, &-ContentBottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &-Title {
        margin-bottom: 40px;

        p {
            text-align: center;
            font-size: 32px;
            font-weight: 500;
            letter-spacing: 0.05em;
        }
    }

    &-Subtitle {
        padding: 0 60px;

        p {
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
        }
    }


    &-FormContent {
        h2 {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.05em;
        }

        padding: 0 60px;
    }

    &-CmsText , &-NewsLetterForm {
        padding: 100px 40px;
    }

    .NewsletterSubscription .Button {
        top: 0
    }
}


@include tablet {
    .NewsletterPage {
        &-Title {
            p {
                font-size: 18px;
            }
        }
    }
}


@include mobile {
    .NewsletterPage {
        &-ContentBottom {
            display: flex;
            flex-direction: column;
        }

        &-ContentTop, {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column-reverse;
        }

        &-Title {
            p {
                font-size: 18px;
            }
        }

        &-Subtitle {
            padding: 0 20px;

            p {
                font-size: 12px;
                line-height: 20px;
            }
        }

        &-FormContent {
            h2 {
                font-size: 18px;
            }

            padding: 0;
        }

        &-CmsText {
            font: 12px;
            pad: 50px;
        }

        &-CmsText , &-NewsLetterForm {
            padding: 20px 10px;
        }
    }
}
