.LPSlider {
    padding: 40px 0 60px;

    img {
        margin-bottom: 24px;
        max-width: 100%;
        width: 100% !important;
        height: auto !important;
    }

    .StaticSlider {
        padding-top: 40px;
    }
}

@include mobile {
    .LPSlider {
        &-Item {
            text-align: center;

            p {
                padding: 0 50px;
            }
        }
    }
}
