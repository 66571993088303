@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

:root {
    --facebook-color: transparent;
    --google-color: transparent;
    --amazon-color: #e99a40;
    --twitter-color: #62bfe4;
    --linkedin-color: #626fe4;
    --yahoo-color: #b94747;
    --color-light: #fff;
    --color-gray: #ccc;
    --color-dark: #000;
}

.SocialLoginButton {
    margin-block-start: 5px;

    img {
        width: auto;
        margin-right: 2rem;
        height: 24px;
    }

    span {
        color: var(--color-dark);
    }

    .Button {
        width: 100%;
        background-color: transparent;

        &:hover span {
            color: var(--color-light);
        }
    }

    &_type {
        &_facebook {
            background-color: var(--facebook-color);
            border-color: var(--color-gray);
        }

        &_google {
            background-color: var(--google-color);
            border-color: var(--color-gray);
        }

        &_amazon {
            background-color: var(--amazon-color);
            border-color: var(--amazon-color);
        }

        &_twitter {
            background-color: var(--twitter-color);
            border-color: var(--twitter-color);
        }

        &_linkedin {
            background-color: var(--linkedin-color);
            border-color: var(--linkedin-color);
        }

        &_yahoo {
            background-color: var(--yahoo-color);
            border-color: var(--yahoo-color);
        }
    }
}


@include mobileAndTablet {
    .SocialLoginButton {
        img {
            margin-right: 10px;
        }
    }
}
