@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Table {
    &-Container {
        max-width: 100%;
        overflow-x: auto;
    }

    &-Empty {
        background-color: var(--table-header-color);
        padding: 18px 21px;
        font-size: 13px;
        line-height: 19.5px;
    }

    &-Table {
        width: 100%;
        margin-block-end: 0;
        table-layout: auto;

        * {
            vertical-align: middle;
        }
    }

    &-Cell {
        font-size: 16px;
        line-height: 24px;
        padding: 16px 32px;
    }
}
