@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RmaPage {
    padding-block-end: 56px;

    @include tabletAndAbove {
        padding-block-end: 100px;
    }

    .Button {
        @include mobileAndTablet {
            width: 100%;
        }

        &_isSubmit {
            @include desktopAndAbove {
                width: 217px;
            }
        }
    }

    &-Wrapper {
        @include page-container;

        &_step {
            &_success {
                text-align: center;

                .Button {
                    @include desktopAndAbove {
                        width: 277px;
                    }
                }
            }

            &_order {
                .Form {
                    @include desktopAndAbove {
                        max-width: 442px;
                    }

                    .Field {
                        margin-block-end: 24px;

                        &-Label {
                            padding-block-end: 8px;
                        }
                    }
                }
            }

            &_form {
                .Field-Wrapper:last-of-type {
                    .Field {
                        margin-block-end: 0;
                    }
                }
            }
        }
    }
}
