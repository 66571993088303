@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.Blog-Sidebar {
    .BlogRecentPosts {
        &-Wrapper {
            margin-top: 4rem;
        }

        &-SectionTitle {
            text-align: left;
        }

        &-Post {
            margin-bottom: 24px;
            display: flex;
            align-items: center;

            &:hover {
                .BlogRecentPosts-PostImage {
                    opacity: 0.8;
                }
            }
        }

        &-PostTitle {
            .TypographyParagraph {
                color: $color-neutral-90;
                font-size: 14px;
            }

            h3 {
                font-size: inherit;
                font-weight: inherit;
                margin: 0;
            }
        }

        &-PostImage {
            flex: 0 0 93px;
        }

        &-PostInfo {
            margin-left: 24px;
        }

        &-Image {
            padding: 0;
            display: block;

            img {
                display: block;
                object-fit: cover;
                position: relative;
                height: 72px;
            }
        }

        &-ReadMore {
            display: none;
        }
    }
}

.HomePage {
    .BlogRecentPosts {
        &-Wrapper {
            padding-top: 74px;
            margin-bottom: 70px;
        }

        &-SectionTitle {
            text-align: center;

            .TypographyHeader {
                margin-bottom: 48px;
            }
        }

        &-Posts {
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        &-Post {
            margin-bottom: 30px;

            &:hover {
                .BlogRecentPosts-PostImage {
                    opacity: 0.8;
                }
            }
        }

        &-PostImage {
            display: block;
        }

        &-ReadMore {
            text-align: right;

            a {
                color: $color-neutral-70;
                letter-spacing: 0.5px;
                display: inline-flex;
                align-items: center;
            }

            svg {
                margin-left: 8px;
                left: 0;
                @include default-transition(left);

                path {
                    @include default-transition(fill);
                    fill: $color-neutral-70;
                }
            }

            &:hover {
                a {
                    color: $color-neutral-90;
                }

                svg {
                    left: 5px;

                    path {
                        fill: $color-neutral-90;
                    }
                }
            }
        }

        &-PostTitle {
            margin-bottom: 8px;

            .TypographyParagraph {
                color: $color-neutral-90;
            }

            h3 {
                font-size: inherit;
                font-weight: inherit;
                margin: 0;
            }
        }

        &-Image {
            img {
                object-fit: cover;
            }
        }
    }
}

@include mobile {
    .HomePage {
        .BlogRecentPosts {
            &-Wrapper {
                padding-top: 25px;
                margin-bottom: 45px;
            }

            &-SectionTitle {
                font-size: 24px;

                .TypographyHeader {
                    margin-bottom: 24px;
                }
            }

            &-PostInfo {
                margin-top: 5px;
            }
        }
    }
}

@include tablet {
    .Blog-Sidebar {
        .BlogRecentPosts {
            &-Post {
                flex-wrap: wrap;
            }

            &-PostImage {
                flex: 0 0 100%;
            }

            &-PostInfo {
                flex: 0 0 100%;
                margin-left: 0;
                margin-top: 16px;
            }

            &-Image {
                img {
                    height: 140px;
                }
            }

        }
    }
}

@include tabletAndAbove {
    .HomePage {
        .BlogRecentPosts {
            &-Post {
                &_first {

                    .BlogRecentPosts {
                        &-PostInfo {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 410px;
                            padding: 32px 32px 16px;
                            background: $color-neutral-5;
                        }

                        &-PostTitle {

                            .TypographyParagraph {
                                font-size: 20px;
                                line-height: 28px;
                            }
                        }

                        &-PostImage {
                            width: 100%;
                        }
                    }
                }

                &_rest {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .BlogRecentPosts {

                        &-PostInfo {
                            flex: 1;
                            padding-left: 26px;
                        }

                        &-PostImage {
                            width: 283px;
                            display: block;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@include desktopAndAbove {
    .HomePage {
        .BlogRecentPosts {
            &-Post {
                &_first {
                    float: left;
                    width: 45.4%;
                }

                &_rest {
                    float: right;
                    width: 49.2%;
                }
            }
        }
    }
}
