#rr_xss_cert {
    #RR__docs {
        #regulaminRR {
            table {
                td {
                    min-width: initial;
                }
            }
        }
    }
}
