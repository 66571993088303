.LPButton {
    height: 52px;
    line-height: 52px;
    border-radius: 40px;
    letter-spacing: 0;

    svg {
        margin-left: 30px;
    }

    &_small {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }

    &_yellow {
        background-color: $lp-color-5;
        border-color: $lp-color-5;
        color: $lp-color-6;
    }

    &_dark {
        background-color: $lp-color-6;
        border-color: $lp-color-6;
        color: $lp-color-2;
    }

    &_white {
        background-color: $lp-color-2;
        border-color: $lp-color-2;
        color: $lp-color-6;
    }
}
