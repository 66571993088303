@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewsletterSubscription {
    .FieldForm-Body {
        display: flex;
    }

    .FieldForm-Fields {
        flex: 1;
    }

    .Field-Wrapper {
        &_type_email {
            margin-bottom: 20px;
        }

        &_type_checkbox {
            span {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0;
            }
        }
    }

    .Button {
        padding-left: 0;
        padding-right: 0;
        width: 105px;
        font-size: 12px;
        height: 48px;
        line-height: 48px;
        position: absolute;
        top: 2px;
        right: 0;
    }

    input {
        background: $color-neutral-20;
        font-size: 12px;
        padding-top: 14px;
        padding-bottom: 14px;
        height: 48px;
    }
}

@include tablet {
    .NewsletterSubscription {
        .Button {
            width: 104px;
            top: 0;
        }
    }
}

@include mobile {
    .NewsletterSubscription {
        .Button {
            top: 0;
        }
    }
}
