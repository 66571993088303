@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PaymentDeadline {
    padding: 3rem 0;

    @include default-list;

    ul,
    ol {
        margin-bottom: 32px;

        li {
            margin-bottom: 8px;

            &::before {
                inset-inline-start: 0;
                top: 8px;
                border-radius: 50%;
            }
        }
    }

    * {
        color: $color-neutral-90;
        line-height: 24px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &-Heading {
        font-size: 16px;
        margin-bottom: 30px;
        font-weight: 500;

        span {
            font-weight: 400;
            color: $color-neutral-90;
        }
    }

    b,
    strong {
        font-weight: 600;
    }
}
