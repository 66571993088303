.LPTextWithImage {
    padding: 76px 0 110px;

    iframe {
        width: 100%;
    }

    &-Content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &-Text {
        top: -28px;
    }

    img {
        display: block;
        width: auto !important;
        height: auto !important;
        max-width: 100%;
    }

    &_hcentered {
        .LPTextWithImage-Text {
            top: 0;
        }
    }

    &_secondary {
        padding: 53px 0 75px;

        .LPTextWithImage {
            &-Content {
                gap: 0;
                grid-template-columns: 1fr 1fr;
            }

            &-Text {
                padding-left: 50px;
                padding-right: 80px;
            }
        }
    }
}

@include mobile {
    .LPTextWithImage {
        padding: 0;

        iframe {
            max-width: 100%;
            height: 300px;
            order: 1;
        }

        &-Text {
            padding: 25px;
            top: 0;
            order: 1;
        }

        .LPBase-Container {
            padding: 0;
        }

        img {
            order: 0;
        }

        &_secondary {
            padding: 35px 0 50px;

            .LPBase-Container {
                padding: 0 25px;
            }

            .LPTextWithImage {
                &-Text {
                    padding: 25px 20px 40px;
                }
            }
        }
    }
}

@include tabletAndAbove {
    .LPTextWithImage {
        &-Content {
            display: grid;
            grid-template-columns: 0.9fr 1.2fr;
            gap: 12%;
        }
    }
}
