@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ShowHideText {
    &-TogglerWrapper {
        margin-top: 5px;

        &_togglerPosition_right {
            text-align: right;
        }
    }

    &-Toggler {
        color: $black;
        text-decoration-line: underline;
        margin-right: 8px;
        font-weight: 500;
        font-size: 14px;
        margin-top: 6px;
    }
}

@include mobile {
    .ShowHideText {
        &-TogglerWrapper {
            &_togglerOnMobilePosition {
                &_right {
                    text-align: right;
                }

                &_left {
                    text-align: left;
                }
            }
        }
    }
}
