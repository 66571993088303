.Inspiration {
    $parent: &;

    *:last-child {
        margin-block-end: 92px;

        @include desktop {
            margin-block-end: 100px;
        }
    }

    &-Title {
        @include page-container;

        .TypographyHeader {
            margin-bottom: 32px;
            font-family: $font-dm-serif-display;
        }
    }

    &-Container {
        @include page-container;
    }

    &-Content {
        *:first-child {
            margin-block-start: 0;
        }

        *:last-child {
            margin-block-end: 0;
        }

        .TypographyHeader {
            text-align: left !important;

            &_variant {
                &_normal {
                    margin-block-end: 16px;
                }

                &_medium {
                    margin-block-end: 24px;
                    font-family: $font-dm-serif-display;
                }
            }
        }
    }

    &-Video {
        display: block;
        aspect-ratio: 16 / 9;
        width: 100%;
        background-color: $color-neutral-20;

        @supports not (aspect-ratio: 16 / 9) {
            padding-block-end: 56.25%;
        }

        iframe,
        video {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &-Image {
        &_decoration {
            --inspiration-image-decoration-margin-left: 51px;
            --inspiration-image-decoration-margin-top: 51px;

            @include desktopAndAbove {
                --inspiration-image-decoration-margin-left: 95px;
                --inspiration-image-decoration-margin-top: 130px;
            }

            margin-block-start: var(--inspiration-image-decoration-margin-top);
            margin-inline-start: var(
                --inspiration-image-decoration-margin-left
            );

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(
                    --inspiration-image-decoration-background,
                    $color-neutral-20
                );
                transform: translate(
                    calc(var(--inspiration-image-decoration-margin-left) * -1),
                    calc(var(--inspiration-image-decoration-margin-top) * -1)
                );
                z-index: -2;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: white;
                transform: translate(-24px, -24px);
                z-index: -1;
            }
        }
    }

    &-ProductsList {
        display: grid;
        grid-column-gap: 24px;
        grid-row-gap: 56px;
        align-items: flex-start;

        @include desktopAndAbove {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-Products {
        @include tabletAndAbove {
            padding-block: 32px;
            padding-inline: 24px;
            border: 1px dashed transparentize($black, 0.8);
            max-width: 100%;
        }

        .TypographyHeader {
            padding-block-end: 16px;
            margin-block: 0 32px;
            text-align: left !important;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                max-width: 64px;
                width: 100%;
                background-color: $color-secondary-75;
            }
        }

        &_image {
            @include mobile {
                display: none;
                visibility: hidden;
                opacity: 0;
                height: 0;
                width: 0;
            }

            &::before {
                content: "";
                background-image: var(--inspiration-products-background);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: $color-neutral-20;
                position: absolute;
                inset-block: 32px;
                inset-inline: 0;
            }
        }
    }

    &_type {
        &_products {
            &_grid {
                display: grid;
                grid-gap: 24px;
                overflow: inherit;

                @include tabletAndAbove {
                    grid-template-columns: auto ;
                }

                @include desktopAndAbove {
                    grid-gap: 48px;
                    margin: 60px auto;

                    .Inspiration-Products_image {
                        height: 100%;
                    }
                }

                .CatalogWidget {
                    margin-block-end: 0;

                    @include tabletAndAbove {
                        display: grid;
                        grid-row-gap: 48px;

                        &-Item {
                            padding-inline: 0;
                        }
                    }

                    @include tablet {
                        grid-template-columns: repeat(2, 1fr);
                        grid-column-gap: 24px;
                    }

                    .ProductCard-AddToCartWrapper {
                        flex-direction: column;

                        & .AddToCart {
                            margin-inline-start: 0;
                            margin-block-start: 10px;
                        }
                    }

                    &-NoProducts {
                        margin-block-end: 0;
                    }
                }

                & > * {
                    max-width: 100%;
                }
            }

            &_recommended {
                margin-block-end: 56px;

                @include tabletAndAbove {
                    margin-block-end: 46px;
                }

                @include desktopAndAbove {
                    margin-block-end: 86px;
                }

                #{$parent}-Content {
                    .TypographyHeader {
                        &_variant {
                            &_medium {
                                @include tabletAndAbove {
                                    margin-block-end: 48px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &_column {
            display: grid;
            grid-row-gap: 56px;
            margin-block-end: 56px;

            @include desktopAndAbove {
                grid-row-gap: 148px;
                margin-block-end: 148px;
            }
        }

        &_fullwidth {
            background-image: var(--inspiration-fullwidth-background);
            background-color: $color-neutral-20;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding-block: 48px;
            margin-block-end: 56px;

            @include tabletAndAbove {
                padding-block: 64px;
                margin-block-end: 100px;
            }

            @include desktopAndAbove {
                padding-block: 158px 165px;
                margin-block-end: 145px;
            }

            #{$parent}-Content {
                @include tabletAndAbove {
                    display: grid;
                    grid-template-columns: 60%;
                }

                .TypographyHeader {
                    &_variant {
                        &_medium {
                            margin-block-end: 32px;
                        }
                    }
                }

                .Button {
                    margin-block-start: 34px;

                    @include mobile {
                        width: 100%;
                    }

                    @include tabletAndAbove {
                        justify-self: flex-start;
                    }
                }
            }
        }

        &_row {
            display: grid;
            grid-row-gap: 24px;
            margin-block-end: 48px;

            @include mobile {
                #{$parent}-Image:last-child {
                    order: -1;
                }
            }

            @include tabletAndAbove {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 24px;
                align-items: center;
                margin-block-end: 64px;
            }

            @include desktopAndAbove {
                grid-column-gap: 48px;
                margin-block-end: 100px;
            }
        }
    }

    &_gap {
        &_small {
            grid-row-gap: 24px;

            @include tabletAndAbove {
                grid-row-gap: 32px;
            }

            @include desktopAndAbove {
                grid-row-gap: 48px;
            }
        }
    }
}
