@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --reset-button-label-color: #{$color-neutral-90};
    --reset-button-background: none;
    --reset-button-icon-color: #{$color-neutral-5};
}

.ResetButton {
    display: flex;
    align-items: center;
    background: var(--reset-button-background);
    position: relative;
    cursor: pointer;

    &-Label {
        font-size: 12px;
        color: var(--reset-button-label-color);

        &::after {
            content: 'x';
            padding-left: 8px;
        }
    }
}
