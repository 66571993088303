@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Notification {
    --notification-height: 40px;

    border-radius: 0;
    line-height: 18px;
    padding-inline: 8px;
    padding-block: 8px;

    &-Icon {
        display: block;
        height: 24px;
        width: 24px;
        min-width: 24px;
        align-self: flex-start;
        margin-inline-end: 13px;
    }

    &_type {
        &_error {
            --notification-error-background: #{$color-other-8};

            & > .Notification-Icon > path {
                fill: var(--notification-error-color) !important;
            }
        }

        &_success {
            --notification-success-background: #{$color-other-9};

            & > .Notification-Icon > path {
                fill: var(--notification-success-color) !important;
            }
        }

        &_info {
            --notification-info-background: #{$color-other-3};
            --notification-info-color: #{$color-neutral-5};

            & > .Notification-Icon > path {
                fill: var(--notification-info-color) !important;
            }
        }
    }

    &_is {
        &_opening {
            animation: onlyFadeInStart var(--animation-duration)
                cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
        }

        &_closing {
            animation: onlyFadeOutStart var(--animation-duration)
                cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
        }
    }

    &-Text {
        font-weight: 400;
        margin-inline-end: 0;
        font-size: 15px;
    }

    &-Button {
        display: none;
    }

    @keyframes onlyFadeInStart {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes onlyFadeOutStart {
        from {
            opacity: 1;
            transform: translateY(0);
        }

        to {
            opacity: 0;
            transform: translateY(-100%);
        }
    }
}
