@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ShowRewardPoints {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 20px 0;

    &-Content {
        font-weight: 400;
        font-size: 14px;
        color: $color-neutral-80;
    }

    &-TextBold {
        font-weight: 600;
        color: $color-neutral-90;
    }
}
