@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Tabs {
    z-index: 1;


    &-Tabs {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        overflow-x: auto;
        margin-bottom: 25px;
    }

    &-Tab {
        color: $color-neutral-70;
        font-size: 16px;
        line-height: 24px;
        margin: 0 30px;
        padding: 8px 0;
        cursor: pointer;
        flex-shrink: 0;

        @include default-transition(color);

        &::after {
            width: 0;
            height: 3px;
            background: $color-primary-70;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;

            @include default-transition(width, .2s);
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: inherit;
            font-size: inherit;
            color: $color-neutral-70;
            margin: 0;
            line-height: 24px;
        }

        &_isActive {
            color: $color-neutral-90;
            font-size: 20px;


            &::after {
                width: 32px;
            }

            h1, h2, h3, h4, h5, h6 {
                color: $color-neutral-90;
                line-height: 24px;
            }
        }

        h2 {
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $black;
            }
        }
    }

    &-Content {
        height: auto;
    }

    &_tabsVariant {
        &_secondary {
            .Tabs {
                &-Tabs {
                    justify-content: left;
                }

                &-Tab {
                    &:first-child {
                        margin-left: 0;
                    }

                    &_isActive {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@include mobileAndTablet {
    .Tabs {
        margin-right: -16px;

        &-Tabs {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: auto;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-Tab {
            margin: 0 12px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }
}

@include mobileAndTablet {
    .Tabs {
        &-Tab {
            &_isActive {
                font-size: 16px;
            }
        }
    }
}

@include desktopAndAbove {
    .Tabs {
        &-Tabs {
            margin-bottom: 50px;
        }
    }
}
