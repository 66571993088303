@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NotificationList {
    inset-block-start: 30px;
    max-width: 462px;
    width: 100%;

    &-Reference {
        position: absolute;
        inset: 0;
        z-index: -1;
    }

    &-Element {
        padding-block-start: 14px;
        width: 462px;
        z-index: 800;
        white-space: initial;
        text-align: left;

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &_isLeft {
                align-items: flex-start;
            }

            &_isRight {
                align-items: flex-end;
            }
        }

        @include mobile {
            width: 200px;

            &_isLeft {
                left: 0;
            }
        }
    }
}

.ProductCard {
    .NotificationList {
        &-Element {
            position: absolute;
            top: 0;
        }
    }
}

@include mobile {
    .NotificationList {
        max-width: 100%;
        inset-block-start: 0;
    }
}
