@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


.CookiePopup {
    background-color: $color-neutral-30;
    opacity: 1;
    position: unset;
    
    &-Container {
        position: fixed;
        bottom: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
        z-index: 2147483647;
    }

    @include desktop {
        padding: 10px;
        inset-inline-start: 0;
        transform: none;
        margin: 0 10%;

        &:dir(rtl) {
            transform: none;
        }
    }

    @include mobile {
        flex-direction: column;
        width: calc(100% - 32px);
        margin-inline: 16px;
        padding: 8px 16px;
    }

    &-Content {
        color: $color-neutral-70;
        font-weight: 500;

        &::after {
            content: '.';
            color: $color-primary-95;
        }
    }

    &-Link {
        font-size: 12px;
        color: $color-primary-95;
        margin-left: 10px;
    }

    &-CTA {
        color: var(--primary-base-color);
        text-transform: uppercase;
        font-weight: 500;

        button {
            padding: 0 15px;
            border: 0;
            --button-height: 48px;
        }

        @include desktop {
            margin-inline-start: 41px;
        }

        @include mobile {
            margin-block-start: 12px;
        }

        &:hover {
            color: var(--primary-dark-color);
        }
    }
}

