:root {
    --default-button-padding: 40px;
    --default-button-padding-mobile: 15px;
    --default-button-height: 55px;
    --medium-button-height: 48px;
    --small-button-height: 40px;
    --default-text-decoration: none;
    --button-border-width: 2px;
    --button-border-bottom-width: 3px;
    --button-width: auto;
    // Primary button
    --button-background: var(--imported_buttons_background_color, #{$color-primary-50});
    --button-border: var(--imported_buttons_border_color, #{$color-primary-50});
    --button-border-radius: var(--imported_buttons_border_radius, var(--default-button-radius));
    --button-color: var(--imported_buttons_color, #{$color-neutral-5});
    --button-height: var(--imported_buttons_height, var(--default-button-height));
    --button-padding: var(--imported_buttons_padding, var(--default-button-padding));
    --button-padding-mobile: var(--imported_buttons_padding, var(--default-button-padding-mobile));
    --button-text-decoration: var(--imported_buttons_text_decoration, var(--default-text-decoration));
    --button-disabled-color: var(--imported_buttons_disabled_color, #{$color-neutral-50});
    --button-disabled-background: var(--imported_buttons_disabled_background, #{$color-teriary-9});
    --button-disabled-border: var(--imported_buttons_disabled_border, #{$color-teriary-9});
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, #{$color-primary-85});
    --button-hover-border: var(--imported_hoverButtons_border_color, #{$color-primary-85});

    @include mobile {
        --button-hover-border: var(--imported_hoverButtons_border_color, #{$color-primary-70});
    }
    --button-hover-color: var(--imported_hoverButtons_color, #{$color-neutral-5});
    --button-hover-height: var(--imported_hoverButtons_height, var(--default-button-height));
    --button-hover-padding: var(--imported_hoverButtons_padding, var(--default-button-padding));
    --button-hover-decoration: var(--imported_hoverButtons_text_decoration, var(--default-text-decoration));
    // Secondary button
    --secondary-button-background: var(--imported_secondaryButtons_background, #{$color-teriary-1});
    --secondary-button-color: var(--imported_secondaryButtons_color, #{$color-neutral-5});
    --secodnary-button-border: var(--imported_secondaryButtons_border_color, #{$color-teriary-1});
    // Secondary button (Hover)
    --secondary-button-hover-background: var(--imported_secondaryButtons_background_color, #{$color-neutral-80});
    --secondary-button-hover-border: var(--imported_secondaryButtons_border_color, #{$color-neutral-80});
    // Outline button
    --outline-button-background: var(--imported_outlineButtons_background, #{$color-primary-50});
    --outline-button-color: var(--imported_outlineButtons_color, #{$color-neutral-5});
    --outline-button-border: var(--imported_outlineButtons_border, #{$color-neutral-5});
    // Outline button (Hover)
    --outline-button-hover-background: var(--imported_outlineHoverButtons_background_color, #{$color-neutral-5});
    --outline-button-hover-border: var(--imported_outlineHoverButtons_border_color, #{$color-neutral-5});
    --outline-button-hover-color: var(--imported_outlineHoverButtons_color, #{$color-primary-50});
    // Outline Inverse button
    --outline-inverse-button-background: var(--imported_outlineButtons_background, transparent);
    --outline-inverse-button-color: var(--imported_outlineButtons_color, #{$color-primary-50});
    --outline-inverse-button-border: var(--imported_outlineButtons_border, #{$color-neutral-35});
    // Outline Inverse button (Hover)
    --outline-inverse-button-hover-background: var(--imported_outlineHoverButtons_background_color, #{$color-primary-50});
    --outline-inverse-button-hover-border: var(--imported_outlineHoverButtons_border_color, #{$color-primary-50});
    --outline-inverse-button-hover-color: var(--imported_outlineHoverButtons_color, #{$color-neutral-5});
    // Outline Dark button
    --outline-dark-button-background: var(--imported_outlineDarkButtons_background, none);
    --outline-dark-button-color: var(--imported_outlineDarkButtons_color, #{$color-neutral-90});
    --outline-dark-button-border: var(--imported_outlineDarkButtons_border, #{$color-neutral-90});
    // Outline Dark button (Hover)
    --outline-dark-button-hover-background: var(--imported_outlineDarkHoverButtons_background_color, #{$color-neutral-90});
    --outline-dark-button-hover-border: var(--imported_outlineDarkHoverButtons_border_color, #{$color-neutral-90});
    --outline-dark-button-hover-color: var(--imported_outlineDarkHoverButtons_color, #{$color-neutral-5});
    // Outline White button
    --outline-white-button-background: var(--imported_outlineWhiteButtons_background, none);
    --outline-white-button-color: var(--imported_outlineWhiteButtons_color, #{$color-neutral-5});
    --outline-white-button-border: var(--imported_outlineWhiteButtons_border, #{$color-neutral-5});
    // Outline White button (Hover)
    --outline-white-button-hover-background: var(--imported_outlineWhiteHoverButtons_background_color, #{$color-neutral-5});
    --outline-white-button-hover-border: var(--imported_outlineWhiteHoverButtons_border_color, #{$color-neutral-5});
    --outline-white-button-hover-color: var(--imported_outlineWhiteHoverButtons_color, #{$color-neutral-90});
    // Outline Dark Transparent Bg button
    --outline-dark-transparent-bg-button-background: var(--imported_outlineDarkButtons_background, #{$white-transparent});
    --outline-dark-transparent-bg-button-color: var(--imported_outlineDarkButtons_color, #{$color-neutral-90});
    --outline-dark-transparent-bg-button-border: var(--imported_outlineDarkButtons_border, #{$color-neutral-90});
    // Outline Dark Transparent Bg button (Hover)
    --outline-dark-transparent-bg-button-hover-background: var(--imported_outlineDarkHoverButtons_background_color, #{$color-neutral-90});
    --outline-dark-transparent-bg-button-hover-border: var(--imported_outlineDarkHoverButtons_border_color, #{$color-neutral-90});
    --outline-dark-transparent-bg-button-hover-color: var(--imported_outlineDarkHoverButtons_color, #{$color-neutral-5});
    // Like link button
    --like-link-button-color: #{$color-neutral-70};
    // Like link button (Hover)
    --like-link-button-hover-color: #{$color-primary-50};
}

.Button {
    @include button;
}

a.Button {
    display: inline-flex;
}

button {
    background-color: transparent;
    cursor: pointer;
}
