@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Breadcrumb {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding-left: 0;
    padding-right: 12px;

    .ChevronIcon {
        display: inline-block;
        width: 12px;
        height: 12px;
        inset-block-end: -2px;
        margin-inline-start: 12px;
        fill: $color-neutral-50;
    }

    &:last-child {
        .ChevronIcon {
            display: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        text-transform: initial;
        color: $color-neutral-50;
        display: inline-block;
        font-weight: 400;
        font-size: 12px;

        &-Name {
            color: inherit;
        }
    }
}

@include mobile {
    .Breadcrumb {
        line-height: 18px;
    }
}
