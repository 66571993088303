@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RmaDetail {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;

    @include tabletAndAbove {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktopAndAbove {
        max-width: 1024px;
    }

    &-Heading {
        margin-block: 0 16px;
        font-weight: 500;
        color: $black;

        & + .TypographyParagraph {
            margin-block: 0 24px;
            top: 0;
        }
    }

    &-Products,
    &-Payoff,
    &-Actions {
        @include tabletAndAbove {
            grid-column: 1/3;
        }
    }

    &-Address,
    &-Order {
        table {
            border: 0;
        }

        tr:not(:last-child) {
            border-bottom: 0;
        }

        tr:first-child {
            th,
            td {
                padding-block-start: 0;
            }
        }

        tr:last-child {
            th,
            td {
                padding-block-end: 0;
            }
        }

        th,
        td {
            padding-inline: 0;
            padding-block: 4px;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            width: 50%;
        }

        td {
            color: $color-neutral-70;
        }
    }

    &-Payoff {
        @include desktopAndAbove {
            max-width: 442px;
        }

        .RmaDetail-Heading {
            margin-block: 0 8px;
        }
    }

    &-Actions {
        padding-block-start: 40px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 1px;
            background-color: $color-neutral-35;
        }
    }

    &-Product {
        display: grid;
        grid-gap: 24px;
        padding-block: 24px;
        border-top: 1px solid $color-neutral-35;

        @include tabletAndAbove {
            grid-gap: 19%;
            grid-template-columns: auto 325px;
        }

        &:last-child {
            border-bottom: 1px solid $color-neutral-35;
        }

        table {
            border: 0;
        }

        tr:not(:last-child) {
            border-bottom: 0;
        }

        tr:first-child {
            th,
            td {
                padding-block-start: 0;
            }
        }

        tr:last-child {
            th,
            td {
                padding-block-end: 0;
            }
        }

        th,
        td {
            padding-inline: 0;
            padding-block: 4px;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            vertical-align: top;
        }

        th {
            color: $color-neutral-70;
        }

        &Wrapper {
            display: grid;
            grid-gap: 36px;

            @include tabletAndAbove {
                grid-template-columns: 110px 1fr;
            }
        }

        &Content {
            @include tabletAndAbove {
                padding-inline-start: 4px;
            }

            & > * {
                color: $black;

                &:not(:last-child) {
                    margin-block-end: 9px;
                }
            }
        }

        &Price {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
        }

        &Name {
            font-size: 13px;
            line-height: 20px;
        }

        &Additional {
            display: flex;

            & > * {
                font-size: 13px;
                line-height: 20px;
                color: $color-neutral-50;

                &:not(:last-child) {
                    margin-inline-end: 14px;
                }
            }
        }

        &Images {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .Image {
                max-width: 45px;
                margin: inherit;
            }
        }
    }
}
