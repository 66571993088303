@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@include desktopAndAbove {
    .Menu {
        &-SubCategoriesWrapper {
            top: -2237px;
            position: absolute;
            width: 100%;
            left: 0;
            transform: none;
            z-index: 0;
            opacity: 0;

            &_isVisible {
                top: 37px;
                opacity: 1;
                z-index: 11;
            }

            &::before {
                display: none;
            }
        }

        &-Link {
            margin-inline: 0 0;
        }

        &-MainCategories {
            @include page-container;
        }
    }


    .Menu-ItemList_type_main {
        a.Menu-Link {
            &:hover {
                color: $color-primary-95
            }

            .Menu-ItemCaption {
                &::before {
                    background-color: $color-primary-95;
                    z-index: 11;
                }
            }
        }
    }

    .Menu-ItemList.Menu-ItemList_type_subcategory {
        padding-top: 40px;
        padding-bottom: 16px;
        display: block;
        column-count: 5;

        .Menu-Link.Menu-Link_isHovered {
            display: none !important;
        }

        .Menu-SubItemWrapper {
            min-width: initial;
            page-break-inside: avoid;
            overflow: hidden;
            margin-bottom: 12px;
        }

        .Menu-SubLevelDesktop {
            .Menu-ItemList {
                margin-bottom: 0;
            }

            .Menu-Link {
                margin-bottom: 0;
                opacity: 0.5;
            }
        }

        a.Menu-Link {
            display: inline-block;
            color: $default-primary-base-color;
            transition: all .3s ease-in-out;

            .Menu-ItemCaption {
                display: inline-block;
                transition: all .3s ease-in-out;
                margin-bottom: 8px;
                font-size: 14px;

                &:hover {
                    color: $color-neutral-50;
                    transition: all .3s ease-in-out;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -4px;
                        height: 1px;
                        width: 100%;
                        background-color: $color-neutral-50;
                        transition: all .3s ease-in-out;
                    }
                }
            }
        }
    }
}
